import Tabulator from "tabulator-tables";
import {TransportMainController} from "../../../transport_main_controller";

export default class extends TransportMainController {
  static targets = [
    "table",
    //   filters
    'search',
    'order_id',
    'period',
    'vehicle_category_ids',
    'vehicle_make_ids',
    'project_ids',
    'status',
    //
    'orderInfo',
    "moreDrivers"
  ]

  connect() {
    // create tabulator table
    if (this.hasTableTarget) {
      this._crateDispatchersTable().then(() => {
        console.log('Table was successfully created.');
      }).catch(error => {
        console.error('Table Error:', error);
      });
    }
  }

  //
  // Actions
  //

  toggleMoreDrivers(event) {
    event.preventDefault();
    if (this.hasMoreDriversTarget) {
      if (this.moreDriversTarget.classList.contains('show')) {
        event.target.innerHTML = event.target.getAttribute('data-less-text');
      } else {
        event.target.innerHTML = event.target.getAttribute('data-more-text');
      }
      this.moreDriversTarget.classList.toggle('show');
    }
  }

  // 1 - Load order info from url
  // 2 - set period value from order start_date and end_date
  // 3 - set vehicle_category_ids from oder info
  // 4 - show order info
  // 5 - filter table
  orderSelected(event) {
    event.preventDefault();
    let order_id = super._getSelectCollectionValues(this.order_idTarget);

    console.log(order_id);
    if (order_id !== "") {
      this._loadOrderInfo(order_id).then(data => {
        if (data) {
          this._setFilterFromOrderInfo(data);
          this.orderInfoTarget.innerHTML = data.order_info_html;
          this.filterTable(event);
        }
      }).catch(error => {
        console.error('Error:', error)
      });
    }
  }

  filterTable(event) {
    event.preventDefault();

    if (this.hasTableTarget) {
      let is_update = false;
      if ((event.target.name === 'search' && event.target.value.length >= 3) || (event.target.name !== 'search' && event.target.value.length >= 0)) {
        is_update = true;
      }
      if (event.target.name !== 'search') is_update = true;

      if (is_update) {
        this._updateTableColumns().then(r => {
          this.tableTarget.tabulator.setFilter(this._getPageFilters());
        });
      }
    }
  }

  //
  // Private
  //

  async _updateTableColumns() {
    const tabulator = this.tableTarget.tabulator;
    const urlWithParams = this._buildTableColumnsUrl();

    console.log(urlWithParams);

    const response = await fetch(urlWithParams);
    const data = await response.json();

    console.log(data);

    if (data.columns) {
      tabulator.setColumns(data.columns);
      tabulator.redraw();
    }
  }

  _buildTableColumnsUrl() {
    const baseUrl = this.tableTarget.tabulator.getAjaxUrl();
    const filters = this._getPageFilters();
    const params = new URLSearchParams();
    params.append('page', 1);
    params.append('per_page', 30);
    filters.forEach((filter, index) => {
      params.append(`filters[${index}][field]`, filter.field);
      params.append(`filters[${index}][type]`, filter.type);
      params.append(`filters[${index}][value]`, filter.value || '');
    });
    params.append('only_columns', 'true');
    return `${baseUrl}?${params.toString()}`;
  }

  _setFilterFromOrderInfo(data) {
    if (this.hasPeriodTarget){
      let start_date = new Date(data.order.start_date);
      let end_date = new Date(data.order.end_date);
      this.periodTarget.flatpickr.setDate([start_date, end_date]);
    }

    if (this.hasVehicle_category_idsTarget) {
      this.vehicle_category_idsTarget.choices.setChoiceByValue(data.order.vehicle_category_ids);
    }
  }

  // {"order":{"id":"895f6fe2-6b99-420f-b39b-349b4641c38c","number":14,"client_present":true,"client_number":null,"client_id":"969e17ac-0658-462a-87cf-d3067246abc7","client_name":"Mariott","client_phone":"+998 71 202 23 33","client_email":null,"client_address":"г.Ташкент, ул.Кичик Бешагач, 126","client_contact_phone":null,"client_contact_email":null,"client_contact_full_name":null,"individual_present":false,"individual_full_name":null,"individual_phone":null,"passengers_count":10,"preferred_car_models":[],"start_date":"2024-05-09T12:52:00.000+05:00","end_date":"2024-05-09T16:00:00.000+05:00","start_point":"Courtyard by Mariott","end_point":"Courtyard by Mariott","period":"09-05-2024 - 09-05-2024","url":"/ru/transport/orders/895f6fe2-6b99-420f-b39b-349b4641c38c"},"order_drivers":[{"driver_id":"6e413e03-45fd-4b6e-9514-04fec90eeec3","driver_full_name":"Adxam Shokirboyev","driver_phone":"+998 95 000 10 04","url":"/ru/transport/drivers/6e413e03-45fd-4b6e-9514-04fec90eeec3","order_drivers":[{"id":"b929002f-2a18-485c-b9a3-1ec19cc091fa","start_at":"2024-05-09T12:52:00.000+05:00","start_at_formatted":"09 мая, 12:52","end_at":"2024-05-09T16:00:00.000+05:00","end_at_formatted":"09 мая, 16:00"}]}]}
  async _loadOrderInfo(order_id) {
    const url = this.data.get("order-info-url");
    const response = await fetch(`${url}&order_id=${order_id}`);
    return await response.json();
  }

  async _crateDispatchersTable() {
    const columns = JSON.parse(this.data.get("table-columns"));
    const filters = this._getPageFilters();
    const url = this.data.get("table-load-url");
    const lang = document.body.dataset.lang;

    await this._initializeTable(
      url,
      this.tableTarget,
      columns,
      filters
    )
  }

  async _initializeTable(url, tableTarget, columns, filters) {
    let tableProps = this._dispatchersTableProps(url, columns, filters);

    tableTarget.tabulator = new Tabulator(tableTarget, tableProps);
    await tableTarget.tabulator.setLocale(super.appLocale);
  }

  _dispatchersTableProps(url, columns, filters) {
    const height = document.getElementById('content').clientHeight;

    let props = {
      virtualDomHoz: false,
      columns: columns,
      persistence: false,
      initialFilter: filters,
      virtualDomBuffer: 8000,
      index: "id",
      height: (height - 150) + "px",
      movableColumns: false,
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 30,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      //
      layout: "fitColumns"
    }

    return Object.assign(super.tabulatorDefaultProps, props);
  }

  _getPageFilters() {
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "search",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasOrder_idTarget) {
      let order_id_value = super._getSelectCollectionValues(this.order_idTarget);
      if (order_id_value[0] !== "") {
        filters.push({
          field: "order_id",
          type: "=",
          value: order_id_value
        })
      }
    }

    if (this.hasPeriodTarget) {
      filters.push({
        field: "period",
        type: "=",
        value: this.periodTarget.value
      })
    }

    if (this.hasVehicle_category_idsTarget) {
      filters.push({
        field: "vehicle_category_ids",
        type: "=",
        value: super._getSelectCollectionValues(this.vehicle_category_idsTarget)
      })
    }

    if (this.hasVehicle_make_idsTarget) {
      filters.push({
        field: "vehicle_make_ids",
        type: "=",
        value: super._getSelectCollectionValues(this.vehicle_make_idsTarget)
      })
    }

    if (this.hasProject_idsTarget) {
      filters.push({
        field: "project_ids",
        type: "=",
        value: super._getSelectCollectionValues(this.project_idsTarget)
      })
    }

    if (this.hasStatusTarget) {
      let value = ""
      if (this.statusTarget.checked) {
        value = this.statusTarget.value
      }
      filters.push({
        field: "status",
        type: "=",
        value: value
      })
    }

    return filters;
  }
}
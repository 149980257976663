import BSN from "bootstrap.native";
import {DriversController} from "../drivers_controller";

export default class extends DriversController {
  static targets = [
    // filters
    "search",
    "license_categories",
    "type_of_employments",
    "driving_experience_filter",
    "driving_experience_year",
    "age_filter",
    "age_year",
    "statuses",
    "project_ids",
    // tabs and tables
    "active_tab", "archive_tab", "active_table", "archive_table",
    // mapping
    "mapping_dropdown", "mapping_checkbox"
  ]

  connect() {
    let isActiveTabActive = this.active_tabTarget.classList.contains('active');
    if (this.hasActive_tableTarget && isActiveTabActive) {
      this._crateActiveTable().then(() => {
        console.log('Active table was successfully created.');
      }).catch(error => {
        console.error('Active table Error:', error);
      });
    }

    let isArchiveTabActive = this.archive_tabTarget.classList.contains('active');
    if (this.hasArchive_tableTarget && isArchiveTabActive) {
      this._createArchiveTable().then(() => {
        console.log('Archive table was successfully created.');
      }).catch(error => {
        console.error('Archive table Error:', error);
      });
    }
  }

  //
  // Actions
  //
  loadActiveTable(event) {
    event.preventDefault();

    let activeTabulator = this.active_tableTarget.tabulator;
    if (activeTabulator) {
      let filterChanged = super._isFiltersChangedForTable(this.active_tableTarget);
      // filter table if filters changed
      if (filterChanged) {
        let filters = this._getPageFilters();
        this._filterActiveTable(filters).then(() => {
          console.log('Active filterTable');
        }).catch(error => {
          console.error('Active filterTable Error:', error);
        });
      }
    } else {
      this._crateActiveTable().then(() => {
        console.log('Active table was successfully created.');
      }).catch(error => {
        console.error('Active table Error:', error);
      });
    }
  }

  loadArchiveTable(event) {
    event.preventDefault();

    let archiveTabulator = this.archive_tableTarget.tabulator;
    if (archiveTabulator) {
      let filterChanged = super._isFiltersChangedForTable(this.archive_tableTarget);
      // filter table if filters changed
      if (filterChanged) {
        let filters = this._getPageFilters();
        this._filterActiveTable(filters).then(() => {
          console.log('Archive filterTable');
        }).catch(error => {
          console.error('Archive filterTable Error:', error);
        });
      }
    } else {
      this._createArchiveTable().then(() => {
        console.log('Archive table was successfully created.');
      }).catch(error => {
        console.error('Archive table Error:', error);
      });
    }
  }

  // table filters action
  filterTable(event) {
    if (event.currentTarget === this.searchTarget) {
      if ( event.currentTarget.value.length < 3 && event.currentTarget.value.length !== 0 ) {
        return;
      }
    }

    let filters = this._getPageFilters();

    // Change dataset filtersChanged to true
    super._filtersChangedForTable(this.active_tableTarget);
    super._filtersChangedForTable(this.archive_tableTarget);


    this._filterActiveTable(filters).then(() => {
      console.log('Active table was successfully filtered.');
    }).catch(error => {
      console.error('Active table filtered Error:', error);
    });
    this._filterArchiveTable(filters).then(() => {
      console.log('Archive table was successfully filtered.');
    }).catch(error => {
      console.error('Archive table filtered Error:', error);
    });


    if (filters.length > 0) {
      this._filtersToHistoryUrl(filters);
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }
  //
  //            PRIVATE
  //
  async _filterArchiveTable(filters) {
    let isArchiveTabActive = this.archive_tabTarget.classList.contains('active');
    let archiveTabulator = this.archive_tableTarget.tabulator;
    if (!this.hasArchive_tableTarget || !isArchiveTabActive || !archiveTabulator) return;

    this._setFilterToTable(archiveTabulator, filters).then(() => {
      super._filtersAppliedForTable(this.archive_tabTarget);
      console.log('Archive table was successfully filtered.');
    }).catch(error => {
      console.error('Archive table filtered Error:', error);
    });
  }

  async _filterActiveTable(filters) {
    let isActiveTabActive = this.active_tabTarget.classList.contains('active');
    let activeTabulator = this.active_tableTarget.tabulator;
    if (!this.hasActive_tableTarget || !isActiveTabActive || !activeTabulator) return;

    await this._setFilterToTable(activeTabulator, filters).then(() => {
      super._filtersAppliedForTable(this.active_tabTarget);
      console.log('Active table was successfully filtered.');
    }).catch(error => {
      console.error('Active table filtered Error:', error);
    });
  }

  async _crateActiveTable() {
    const columns = JSON.parse(this.data.get("table-columns"));
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    const filters = this._getPageFilters();
    const url = this.data.get("active-table-load-url");
    const lang = document.body.dataset.lang;
    const persistenceID = "transport_driver_active_table_" + lang;

    await this._createTable(
      url,
      this.active_tableTarget,
      persistenceID,
      columns,
      sort_column,
      sort_dir,
      filters
    )
  }

  async _createArchiveTable() {
    const columns = JSON.parse(this.data.get("table-columns"));
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    const filters = this._getPageFilters();
    const url = this.data.get("archive-table-load-url");
    const lang = document.body.dataset.lang;
    const persistenceID = "transport_driver_archive_table_" + lang;

    await this._createTable(
      url,
      this.archive_tableTarget,
      persistenceID,
      columns,
      sort_column,
      sort_dir,
      filters
    )
  }

  // _getPageFilters() {
  //   let filters = []
  //
  //   if (this.hasSearchTarget) {
  //     filters.push({
  //       field: "q",
  //       type: "=",
  //       value: this.searchTarget.value
  //     })
  //   }
  //
  //   if (this.hasLicense_categoriesTarget) {
  //     filters.push({
  //       field: "license_categories",
  //       type: "=",
  //       value: super._getSelectCollectionValues(this.license_categoriesTarget)
  //     })
  //   }
  //
  //   if (this.hasDriving_experience_filterTarget) {
  //     filters.push({
  //       field: "driving_experience_filter",
  //       type: "=",
  //       value: this.driving_experience_filterTarget.value
  //     })
  //   }
  //
  //   if (this.hasDriving_experience_yearTarget) {
  //     filters.push({
  //       field: "driving_experience_year",
  //       type: "=",
  //       value: this.driving_experience_yearTarget.value
  //     })
  //   }
  //
  //   if (this.hasAge_filterTarget) {
  //     filters.push({
  //       field: "age_filter",
  //       type: "=",
  //       value: this.age_filterTarget.value
  //     })
  //   }
  //
  //   if (this.hasAge_yearTarget) {
  //     filters.push({
  //       field: "age_year",
  //       type: "=",
  //       value: this.age_yearTarget.value
  //     })
  //   }
  //
  //   if (this.hasStatusesTarget) {
  //     filters.push({
  //       field: "statuses",
  //       type: "=",
  //       value: super._getSelectCollectionValues(this.statusesTarget)
  //     })
  //   }
  //
  //   if (this.hasType_of_employmentsTarget) {
  //     if (this.type_of_employmentsTarget.value.length) {
  //       filters.push({
  //         field: "type_of_employments",
  //         type: "=",
  //         value: super._getSelectCollectionValues(this.type_of_employmentsTarget)
  //       })
  //     }
  //   }
  //
  //   return filters;
  // }
}